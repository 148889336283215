<template>
  <svg
    class="arrow-nav-right"
    width="100%"
    height="100%"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 13L12 7L12 7L7.33333 1M12 7L7.15493e-08 7"
      stroke="currentColor"
    />
  </svg>
</template>
